<template>
  <div>
    <div class="backgroundSWV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarger">Hintergrundwissen</v-card-title>
      </v-card>
      <br />
      <br />
      <v-card>
        <v-card-text>
          <p>
            Im Laufe der ersten Schuljahre sollen Lernende
            <strong>ein tragfähiges Stellenwertverständnis</strong> aufbauen,
            das im Laufe der Zeit immer weiter ausdifferenziert wird. Ein
            fehlendes oder unzureichend ausgeprägtes Stellenwertverständnis
            erschwert vielen Kindern das Mathematiklernen (z.B. Moser Opitz &
            Schmassmann, 2007; Wartha & Schulz, 2014). Kinder mit einem
            schlechter ausgebildeten Stellenwertverständnis machen mehr Fehler
            und nutzen ein kleineres Repertoire an Rechenstrategien, als Kinder,
            die im Vergleich bessere Kenntnisse des Stellenwertsystems haben
            (Carpenter, Franke, Jacobs, Fennema & Empson, 1998). So greifen
            Kinder mit unzureichendem Stellenwertverständnis für die Addition
            und Subtraktion mehrstelliger Zahlen beispielsweise häufig auf den
            schriftlichen Algorithmus zurück oder rechnen ziffernweise
            (Carpenter et al., 1998; Thompson & Bramald, 2002), anstatt mit
            geschickten Strategien im Kopf oder halbschriftlich zu rechnen.
            Zudem wurde in verschiedenen Studien ein sicheres
            Stellenwertverständnis als Prädikator für die weiteren
            Mathematikleistungen sowohl in der Primarstufe (Möller, Pixner,
            Zuber, Kaufmann & Nürk, 2011) als auch für die weiterführende Schule
            (Moser Opitz, 2007) nachgewiesen.
          </p>
          <p>
            Beim Stellenwertverständnis geht es nicht nur um die Zahlen selbst,
            sondern auch um ihre Schreibfiguren (Krauthausen & Scherer, 2007, S.
            17; Schipper, 2009, S. 119). In den ersten sechs Schuljahren sollen
            die Lernenden verstehen, wie Zahlen im Zehnersystem dargestellt
            werden. Das Zehnersystem zeichnet sich dadurch aus, dass es allein
            mit 10 Ziffern von 0 bis 9 möglich ist, Zahlen in beliebiger Größe
            darzustellen und mit ihnen zu operieren.
          </p>
          <p>
            Auf den nächsten drei Seiten werden nun die drei Hintergrundfacetten
            (1. Bündeln und Entbündeln, 2. Stellenwert und Zahlenwert und 3.
            Darstellungen vernetzen) thematisiert.
          </p>
          <br />
          <AppLiteraturSWV />
        </v-card-text>
      </v-card>
    </div>
    <AppBottomNavSWV
      back="/stellenwertverstaendnis"
      next="/stellenwertverstaendnis/hintergrundwissen/buendeln-und-entbuendeln"
    />
  </div>
</template>

<script>
import AppBottomNavSWV from "@/common/AppBottomNavSWV";
import AppLiteraturSWV from "@/common/AppLiteraturSWV";

export default {
  components: {
    AppBottomNavSWV,
    AppLiteraturSWV,
  },
};
</script>

<style scoped>
#app .titleLarger {
  font-weight: normal;
  font-size: xx-large;
}
</style>
